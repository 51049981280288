@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

*,
*::before,
*::after {
  box-sizing: border-box; /* Inclui padding e border na largura total de todos os elementos */
}

html, body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100vw;
  background-color: #1f2937;
  font-family: 'Roboto', sans-serif; /* Definindo Roboto como fonte padrão */
}

nav {
  width: 100%; /* Garante que a navbar ocupe 100% da largura do body */
}
